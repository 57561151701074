import logo from './logo.svg';
import './App.css';
import ReactPixel from 'react-facebook-pixel';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import Cookies from 'js-cookie';

const Exit = (props) => {

  // let cep = Cookies.get('vl-cep').split('=');

  //Save FBCLID
  const fbclidFromCookie = Cookies.get('_fbc');
  const fbpFromCookie = Cookies.get('_fbp') || 'fb.1.1.1';
  const epoch = Math.floor(new Date().getTime() / 1000);
  const [searchParams, setSearchParams] = useSearchParams();
  const generatedFbclid = `fb.1.${epoch}.${searchParams.get("fbclid")}`
  const fbclid = fbclidFromCookie || generatedFbclid || '';

  //All Other URL Params
  const adId = searchParams.get("ad_id") || 'null';
  const adSetId = searchParams.get("adset_id") || 'null';
  const campaignId = searchParams.get("campaign_id") || 'null';
  const adName = searchParams.get("ad_name") || 'null';
  const adSetName = searchParams.get("adset_name") || 'null';
  const campaign_name = searchParams.get("campaign_name") || 'null';
  const source = searchParams.get("source") || 'null';
  const placement  = searchParams.get("placement") || 'null';
  const location = window.location.origin;

  let clickUrl = `https://sunnygrassland.com/536f9045-46be-4e03-9fa3-81e4c88a6e96?fbp=${fbpFromCookie}&adset_id=${adSetId}}&campaign_id=${campaignId}&ad_name=${adName}&adset_name=${adSetName}&campaign_name=${campaign_name}&source=${source}&placement=${placement}&url=${location}&fbclid=${fbclid}`;
  
  console.log(`FBP From Cookie: ${fbpFromCookie}`);



  const cta = searchParams.get('cta');
  const dynamicCreative = {};

  switch(cta) {
    case 'call':
      console.log('call')
      dynamicCreative.link = "tel:+18888888888"
      dynamicCreative.button = "(888) 888-8888"
      break;
    default:
      console.log('default')
      dynamicCreative.link = clickUrl
      dynamicCreative.button = "CONTINUE";
  }


  return (

    <div className="mx-auto md:px-0 max-w-3xl container ">
    <div className="p-6 border-t-2 border-blue-500 mt-4 bg-gray-300 text-center shadow-xl px-4 mx-4">
      <div className="md:text-xl text-sm" style={{opacity: "1", display: "block"}}>
        <h3 className="text-black text-xl md:text-2xl font-bold">Thank-you</h3>
        <h3 className="mt-2 text-xl md:text-2xl">There are currently (3) studies available that are paying<span className="text-blue-500 font-"> $2350 </span></h3>
        <h3 className="mt-2 text-xl md:text-2xl">Your spot is being held. To get started tap below.</h3>
        <a onClick={() => ReactPixel.track('ViewContent')} href={dynamicCreative.link}><button className="capitalize font-bold w-full bg-green-500 hover:bg-green-400 border-0 text-white my-4 p-5 md:text-2xl text-xl">{dynamicCreative.button}</button></a>
        <p className="text-base font-bold">Act Fast! Your spot is being held for a<span className="text-red-500"> limited time. </span>
        </p>
        <div className="px-4 py-1 w-max mx-auto mt-2 text-xl">Your spot is being held for: <span className="bg-yellow-300">
            <span>2 Minutes</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Exit;
