import logo from './logo.svg';
import './App.css';
import {useState, useEffect} from 'react';

const Main = (props) => {

  const [questionIndex, setQuestionIndex] = useState(0);

  const questionArray = [
    'Have you been diagnosed with a chronic medical condition in the last 20 years?',
    'Were you officially diagnosed by a doctor?',
    'Do you need professional help completing your daily functions such as Showering or Eating?',
  ]

  const questionIndexPlusOne = questionIndex + 1;


  return (

    

        <div>
          <div className="mx-auto md:px-0 max-w-3xl container ">
            <div className=" w-full px-4 py-2">
              <h1 className="text-center rounded-md py-4 font-bold break-normal text-2xl md:text-3xl mx-auto ">Big Pharma Sets Up a $62 Million Fund For Affected Americans</h1>
              <p className="text-gray-300 my-4">
                <span className="font-bold text-blue-500"> Last Update: 14 hours ago</span> 
              </p>
              <img src={require('./files/hero.ac111571.png')} className="mx-auto my-4" />
              <p className="text-xl text-center my-2 ">Pharmaceutical Giants Pfizer and Johnson & Johnson setup a $62 Million account to fund a new study that's paying Americans with Chronic Medical Conditions <span className="text-blue-500 font-bold"> THOUSANDS </span> every single day.</p>
              <p className="text-xl text-center my-2 "></p>
              <p className="text-xl text-center my-2 ">Any U.S. citizen who has been diagnosed with a Chronic Condition may apply to receive a piece of the $62 Million.</p>
              <p className="text-xl text-center my-2 ">It's <span className="text-blue-500 font-bold"> easy </span>and completely <span className="text-blue-500 font-bold"> FREE </span>to check eligibility. To get started, answer 2 questions below.</p>
            </div>
            <div className="px-4 py-4">
              <div name="steps">
                <div className="md:mt-4 mt-0">
                  <h3 className="font-bold text-center md:text-2xl text-xl mb-4">{questionArray[questionIndex]}</h3>
                  <div className="grid grid-cols-1 gap-6">
                    <button className="flex justify-center bg-green-700 hover:bg-green-600  tracking-wide text-white font-bold rounded-2xl w-3/4 mx-auto border-b-2 border-green-900 uppercase shadow-md py-6 text-2xl px-6 items-center" onClick={() => questionArray[questionIndexPlusOne] ? setQuestionIndex(questionIndex + 1) : props.changePage('exit')}>Yes</button>
                    <button className="flex justify-center bg-green-700 hover:bg-green-600  tracking-wide text-white font-bold rounded-2xl w-3/4 mx-auto border-b-2 border-green-900 uppercase shadow-md py-6 text-2xl px-6 items-center" onClick={() => questionArray[questionIndexPlusOne] ? setQuestionIndex(questionIndex + 1) : props.changePage('exit')}>No</button>
                    <button className="flex justify-center bg-green-700 hover:bg-green-600  tracking-wide text-white font-bold rounded-2xl w-3/4 mx-auto border-b-2 border-green-900 uppercase shadow-md py-6 text-2xl px-6 items-center" onClick={() => questionArray[questionIndexPlusOne] ? setQuestionIndex(questionIndex + 1) : props.changePage('exit')}>I don't know</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer footer-center p-4 mt-8 text-base-content max-w-3xl mx-auto" style={{rowGap: "1rem", marginTop: "44em"}}>
            <div className="grid grid-flow-col gap-4 mb-8">
              <a className="link link-hover text-blue-500" href="https://galiolawoffices.com/terms">Terms &amp; Conditions</a>
              <p className="text-blue-500">|</p>
              <a className="link link-hover text-blue-500" href="https://galiolawoffices.com/privacy">Privacy Policy</a>
            </div>
            <div>
              <p>Copyright © 2023 - All right reserved Good Savings Journal.</p>
            </div>
          </footer>
        </div>
  );
}

export default Main;
