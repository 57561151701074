import logo from './logo.svg';
import './App.css';
import Main from './Main.js';
import Exit from './Exit.js';
import {useState, useEffect} from 'react';
import ReactPixel from 'react-facebook-pixel';
import Cookie from 'js-cookies';

function App() {

  let fbPixelId;
  const location = window.location.origin;
  const searchParams = new URLSearchParams(window.location.search);

  if(searchParams.has("pixel")){
    console.log("Received pixel")
    fbPixelId = searchParams.get("pixel");
  }
 

  useEffect(() => {
    ReactPixel.init(fbPixelId);
    ReactPixel.pageView();
    console.log("FB Pixel ID: " + fbPixelId);
  }, []);

  const [view, setView] = useState("main");

  const changePage = (page) => {
    setView(page);
  }

  const renderSwitch = (view) => {
    switch(view){
      case "main":
        return <Main changePage={changePage}/>
      case "exit":
        return <Exit />
    }
  }

  return (
    <div id="root">
      <div className="App">
      <div name="header">
            <nav className="bg-blue-700 p-3 mt-0 w-full">
              <div className="container mx-auto flex items-center justify-between align-middle md:px-48">
                <h3 className="mx-auto text-white font-bold text-center">HUGE Payout From Big Pharma!</h3>
              </div>
            </nav>
          </div>
        {renderSwitch(view)}
      </div>
    </div>
  );
}

export default App;
